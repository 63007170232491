<script setup>
import TwoFactorAuth from "@/pages/TwoFactorAuth.vue";
import { Field, Form } from "vee-validate";
import { computed, onMounted, ref } from "vue";
import * as Yup from "yup";
import { useAuthStore } from "./stores/auth.store";
import { useBettorStore } from "./stores/bettor.store";
import { useLoaderState } from "./stores/loader.store";
import { useUserStore } from "./stores/user.store";
const store = useAuthStore();
const userStore = useUserStore();
const useBettor = useBettorStore();
const isLoading = useLoaderState();
const activationMessage = computed(() => store.$state.activationMessage);
const responseStatus = computed(() => userStore.$state.responseStatus);
const applicationStatus = computed(() => useBettor.$state.applicationStatus);
const applicationStatusMessage = computed(() => useBettor.$state.appStatusCause);
const otpEnabled = computed(() => store.$state.user.otp_enabled);
const optAuthenticate = computed(() => store.$state.opt_authenticate);
const otpVerified  = computed(() => store.$state.user?.otp_verified);
const showActivationStatusModal = ref(false);
const showAcceptanceStatusModal = ref(false);
const showUpdatePasswordModal = ref((false));
const isLoggedIn = computed(() => store.$state.user ? true : false);
const acceptButtonEnabled = ref(false);
let isScrolling = false;
const schema = Yup.object().shape({
  license_key: Yup.string().required("License key is required"),
});


onMounted(() => {
  const element = document.querySelector('.textAll');
  if (element) {
    element.addEventListener('scroll', handleScroll);
    setInterval(checkScrollPosition, 200); // Check every 200 milliseconds
  }
  store.verifyActivationKey({ license_key: "" }).then((res) => {
    let activationStatus = computed(() => store.$state.activationStatus);
    showActivationStatusModal.value = !activationStatus.value;
  });
  useBettor.getApplicationStatus()
  setInterval(useBettor.getApplicationStatus, 60000);
});

const activationKeyHandler = async (values) => {
  await store.verifyActivationKey({ license_key: values.license_key });
  let activationStatus = computed(() => store.$state.activationStatus);
  if (activationStatus.value === true) {
    // Reload the page
    console.log(activationStatus.value);
    window.location.reload();
  }
};
// const serverCross = () => {
//   userStore.responseStatus = false;
// }

const handleScroll = (el) => {
  isScrolling = true;
}
const checkScrollPosition = () => {
  if (!isScrolling) {
    const element = document.querySelector('.textAll');
    if (element) {
      const { scrollHeight, scrollTop, offsetHeight } = element;
      const scrollPercentage = (scrollTop + offsetHeight) / scrollHeight;

      // You can adjust the threshold percentage as needed
      const thresholdPercentage = 0.9; // 90%

      if (scrollPercentage >= thresholdPercentage) {
        acceptButtonEnabled.value = true;
      }
    }
  }

  isScrolling = false;
};
</script>

<template>
  <div class="tokenInput" v-if="showActivationStatusModal || responseStatus == 200">
    <Form id="form" @submit="activationKeyHandler" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
      <div class="p-3 bg-white rounded-3">
        <div class="my-2 d-flex justify-content-center align-items-center">
          <img style="max-width: 45px" src="/images/only-logo.png" class="img-fluid me-1" alt="" />
          <img style="max-width: 115px" src="/images/p-logo-new.png" class="img-fluid ms-1 me-3" alt="" />
        </div>
        <label for="activation_key" class="form-label fw-bold">License Key</label>
        <Field type="text" class="form-control" name="license_key" id="license_key" placeholder="Enter license key"
          :class="{ 'is-invalid': errors.license_key }" />
        <div class="text-center">
          <button id="licenseValidateApp" class="btn bgProhib my-3" :disabled="isSubmitting">
            <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1"></span>
            Validate
          </button>
        </div>
        <div class="errorMessage text-danger" v-if="activationStatus">
          {{ activationMessage }}
        </div>
      </div>
    </Form>
  </div>
  <div class="tokenInput onCrossHide" v-if="responseStatus == 300">
         <div class="modal-content w-50">
             <div class="modal-body">
               <div class="row">
                 <div class="col-12 text-center">
                   <i class="fa fa-warning fa-3x text-danger"></i>
                   <p class="fw-bold my-3">Sorry for the inconvenience. The Server is currently down, please try again later.</p>
                 </div>
               </div>
             </div>
         </div>
  </div>
  <div class="tokenInput onCrossHide" v-if="applicationStatus">
         <div class="modal-content w-50">
             <div class="modal-body">
               <div class="row">
                 <div class="col-12 text-center">
                   <i class="fa fa-warning fa-3x text-danger"></i>
                   <p class="fw-bold my-3">{{ applicationStatusMessage }}</p>
                 </div>
               </div>
             </div>
         </div>
  </div>
  <!-- end of showActivationStatusModal -->
 <!--Privacy Policy Modal -->
 <div class="modal fade addShow"  id="openPrivacyModal" aria-labelledby="openPrivacyModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex justify-content-between">
          <h4 class="text-center fw-bold text-decoration-underline">Privacy Policy</h4>
          <a href="/files/prohibet-privacy.pdf" download>
                  <button id="appDownloadPrivacyPolicy" class="btn border-secondary border text-secondary mb-2">
                    Download Privacy Policy
                  </button>
                </a>
              </div>
          <div id="appHandleScroll" class="textAll my-3" @scroll="handleScroll">
            <p>
              This Privacy Policy (“Policy”) describes the data protection practices of Secure Sports Solutions, LLC d/b/a
              ProhiBet (“ProhiBet” “we” “us”). We are committed to protecting the privacy of information collected and
              ensuring the confidentiality and security of all data collected. This Policy outlines how ProhiBet collects,
              uses, and safeguards personal information shared with ProhiBet in accordance with applicable privacy laws in
              order to create a safe and secure method to facilitate data transfer between stakeholders in the sports
              betting ecosystem regarding prohibited sports betting participants and any other related services
              (collectively, the “Services”).
            <div class="fw-bold my-2">Information We Collect</div>
            In order to provide the Services mentioned herein, ProhiBet partners with United States based sports betting
            operators (“Operators”) and United States professional sports leagues (“Leagues”) (collectively, the
            “Partners”). Partners will have access to their own private servers hosted by ProhiBet. Operators are
            responsible for uploading lists of persons for which wagers were accepted while Leagues are responsible for
            uploading lists of prohibited sports betting participants. The information uploaded by the Partners pertaining
            to prohibited sports betting participants and persons for which wagers were accepted may contain personally
            identifiable information such as names, address, date of birth, or other identifiers associated with
            prohibited sports betting participants. The Partners are responsible for uploading their lists to the ProhiBet
            platform located within the Partner’s servers and the ProhiBet platform with then fully encrypt the list and
            transmit the encrypted hashes. ProhiBet will not decrypt the files uploaded by the Partners to ProhiBet’s
            platform, unless a Partner provides ProhiBet with its encryption keys and then in such instance only with
            respect to the Partner’s information. ProhiBet’s proprietary technology is fully capable of performing the
            Services contained herein while simultaneously keeping the uploaded files encrypted.

            <div class="fw-bold my-2">Purpose of Collection</div>
            The ProhiBet platform uses the uploaded encrypted files provided by the Partners for the sole purpose of
            identifying potential matches of prohibited sports betting participants who have created sports betting
            accounts or otherwise placed wagers on an Operator’s platform. We do not use the encrypted files for any other
            purpose, and we do not share or disclose the contents of the encrypted files to any third parties, except as
            required by law or to comply with legal obligations. ProhiBet, in order to enhance the Services contained
            herein, is capable of collecting certain statistical information from the lists provided by the Partners,
            including, but not limited to, how many individuals are included on the lists provided. ProhiBet is capable
            of, and warrants to, collecting the necessary statistical information while simultaneously keeping the files
            fully encrypted.

            <div class="fw-bold my-2">Data Security</div>
            ProhiBet takes the security of the data collected through the performance of Services extremely seriously. We
            implement reasonable and appropriate security measures to protect against unauthorized access, loss, theft,
            and alteration of the encrypted files and any other data we transfer, collect and store. These measures may
            include, but are not limited to, encryption, firewalls, access controls, and regular security audits. However,
            no security system is impenetrable. Accordingly, we do not guarantee the security of our databases, nor that
            information supplied by the Partners won't be intercepted while being transmitted to us over the Internet or
            other any network. Any information transmitted to ProhiBet is done at the Partners own risk. We will make any
            legally required disclosures of any breach of the security, confidentiality, or integrity of any
            electronically stored “personal data” (as defined in applicable state statutes on security breach
            notification) to Partners via email or conspicuous posting through the ProhiBet platform in the most expedient
            time possible and without unreasonable delay, as consistent with (i) the legitimate needs of law enforcement
            or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of
            the data system. In order to enhance the security of our platform and protect the privacy of our Partners,
            we require the use of Multi-Factor Authentication (“MFA”) for all user logins. MFA adds an additional layer
            of verification beyond the standard username and password, thereby reducing the risk of unauthorized access
            to an account. By using our services, you agree to comply with this MFA requirement and acknowledge that
            failure to do so may result in restricted access to certain features or areas of our platform.

            <div class="fw-bold my-2">Data Retention</div>
            ProhiBet will retain the encrypted files uploaded by Operators and Leagues and any other data collected
            through the performance of Services for as long as necessary to fulfill the purposes of Services, unless
            otherwise required by law or to comply with legal obligations. ProhiBet shall promptly inform the Operators or
            Leagues in the event ProhiBet is required by law to remove any data uploaded by the Partners from the ProhiBet
            server.

            <div class="fw-bold my-2">International Transfer </div>
            By accessing and using ProhiBet, the Partners acknowledge and agree that ProhiBet controls and operates all
            parts of ProhiBet from its offices in the United States. Unless expressly stated to the contrary, ProhiBet
            makes no representation that ProhiBet is appropriate for or will be available for use in other locations. If
            Partners access the ProhiBet platform from outside the United States, Partners acknowledge and agree that
            their information may be transferred to and maintained on computers and servers located outside of their
            respective states, provinces, countries, or other governmental jurisdictions where the privacy laws may not be
            as protective as those in their jurisdiction. Partners’ consent to the Policy followed by the submission of
            their respective data represents an agreement to the transfer of such information to the United States and the
            collection, use, and disclosure of their information in accordance with United States law and our Privacy
            Policy.

            <div class="fw-bold my-2">User Rights</div>
            The Partners have the right to access their respective encrypted files for the purposes of correcting or
            removing information previously uploaded. The Partners will be provided with instructions on how to properly
            access their respective files and make any necessary changes.

            <div class="fw-bold my-2">Cookies and Tracking Technologies</div>
            ProhiBet does not use cookies or any other tracking technologies on our website or within the ProhiBet
            platform.

            <div class="fw-bold my-2">Third-Party Services</div>
            ProhiBet may use third-party service providers to assist with the operation of Services such as hosting, data
            storage, and analytics. These service providers may have access to the encrypted files uploaded by the
            Partners. Such access shall be granted only to the extent necessary to perform their services for us. Under no
            circumstances shall any third-party service provider assisting with the operation of the Services contained
            herein be able to decrypt the files uploaded by the Partners. Any third-party service providers used for the
            Services contained herein are contractually obligated to maintain the confidentiality and the appropriate
            level of security.

            <div class="fw-bold my-2">Updates to Policy </div>
            ProhiBet reserves the right to amend this Policy at any time by adding new terms or by deleting and or
            amending existing terms. Such modifications will be effective as of the date the updated terms are posted on
            ProhiBet’s platform. Partners’ continued access and use of the ProhiBet platform constitutes their
            acknowledgment of such modifications and their agreement to abide, and be bound, by the Policy as modified.
            Partners must cease their use of ProhiBet if they do not accept such modification(s).

            <div class="fw-bold my-2">Contact Information </div>
            Please feel free to contact ProhiBet with any questions you may have regarding this Policy. We may be
            contacted at legal@prohibet.com.
            <div class="mt-4"> Last updated: 04/05/2024 </div>
            </p>
          </div>
          <div class="text-end">
          <button id="scrollBtnClose" :disabled="!acceptButtonEnabled" data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
            class="btn bgProhib">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end of Privacy Policy Modal -->
  <UpdatePassword :showUpdatePasswordModal="showUpdatePasswordModal"></UpdatePassword>
    <div v-if="isLoggedIn && otpEnabled && !optAuthenticate && otpVerified">
      <Verify2FA />
    </div>
    <div v-else-if="isLoggedIn && otpEnabled && !optAuthenticate && !otpVerified">
      <TwoFactorAuth
        :twoFactorAuthCheck=true
        @cancel2FAModal="cancel2FAModal"
      ></TwoFactorAuth>
    </div>
    <div v-else-if="isLoggedIn">
        <div id="wrapper" class="active">
          <Header></Header>
          <SideBar :showAcceptanceStatusModal="showAcceptanceStatusModal"></SideBar>
          <router-view />
        </div>
    </div>
    <div v-else>
      <Login />
    </div>
  <!-- <div id="wrapper" class="active" v-if="isLoggedIn">
    <Header></Header>
    <SideBar :showAcceptanceStatusModal="showAcceptanceStatusModal"></SideBar>
    <router-view />
  </div> -->
  <!-- <div v-else-if="checkOTPStatus == 0">
    <Verify2FA />
  </div> -->
  <!-- <div v-else>
    <Login />
  </div> -->

  <DialogsWrapper />
  <!-- set progressbar -->
  <vue-progress-bar></vue-progress-bar>
  <notifications position="bottom right" />
  <loader v-if="isLoading.state" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40"
    objectbg="#999793" opacity="80" name="circular"></loader>
</template>
<script>
import Header from "@/components/Layout/Header";
import SideBar from "@/components/Layout/SideBar";
import Login from "@/pages/Login.vue";
import UpdatePassword from "@/pages/UpdatePassword";
import Verify2FA from "@/pages/Verify2FA.vue";
export default {
  name: "AppLayout",
  components: { SideBar, Header , UpdatePassword },
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    // this.$Progress.finish();
  },
  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    // this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        // this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      // this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      // this.$Progress.finish();
    });
  },
};
</script>
