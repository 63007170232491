// src/composables/useConfig.js


import { onMounted, ref } from 'vue';

export const useConfig = () => {
  const config = ref(null);
  const defaultConfigPath = '/configs/config.default.json';

  const loadConfig = (type) => {
    type = type || 'default';
    const configFilePath = `/configs/config.${type}.json`;
    fetch(configFilePath)
      .then((response) => response.ok ? response.json() : fetch(defaultConfigPath).then(res => res.json()))
      .then((data) => {
        config.value = data;
        localStorage.setItem('selectedConfigType', type);
      })
      .catch(() => {
        fetch(defaultConfigPath)
          .then((res) => res.json())
          .then((defaultConfig) => (config.value = defaultConfig));
      });
  };

 onMounted(() => {
    const storedType = localStorage.getItem('selectedConfigType');
    const envType = process.env.VUE_APP_TYPE;
    loadConfig(storedType || envType);
  });

  return { config, loadConfig };
};
